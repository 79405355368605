
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'ImpressionPixel',
})
export default class ImpressionPixel extends Vue {
	@Prop({ required: true }) impressionTag: string;
}
