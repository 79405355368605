
import {
	SetInFollowedListDocument,
	SetInFollowedListMutation,
	SetInFollowedListMutationVariables,
} from '@/components/watchlist/graphql/mutations/SetInFollowedList.mutation';
import { preventNonSignedInAction } from '@/helpers/prevent-non-signed-in-action-helper';
import { toastSuccess, toastError, addToast } from '@/helpers/toast/toast-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import Vue from 'vue';
import { Component, Inject, InjectReactive } from 'vue-property-decorator';
import { FollowedListsCountKey, FollowedListsCountRefetchKey } from '@/constants/ListsCountProvider.vue';

@Component({ name: 'FollowListMutationMixin' })
export default class FollowListMutationMixin extends Vue {
	@InjectReactive(FollowedListsCountKey) followedListsCount: number;
	@Inject(FollowedListsCountRefetchKey) followedListsCountRefetch: () => void;

	async mixin_onFollowToggle({
		listId,
		isFollowed,
		listName,
	}: {
		listId: string;
		isFollowed: boolean;
		listName: string;
	}) {
		const preventAction = await preventNonSignedInAction();

		if (preventAction || !listId) {
			return;
		}

		if (!isFollowed && this.followedListsCount >= 20) {
			return addToast({
				message: this.$t('WEBAPP_FOLLOWLISTLIMITREACHED', { maxFollowedLists: 20 }) as string,
			});
		}

		this.mixin_setInFollowedList({ listId, isFollowed, listName });
	}

	async mixin_setInFollowedList({
		listId,
		isFollowed,
		listName,
	}: {
		listId: string;
		isFollowed: boolean;
		listName: string;
	}) {
		try {
			const message = isFollowed
				? this.$t('WEBAPP_HASBEENREMOVEDFROMYOURLIBRARY', { listName })
				: this.$t('WEBAPP_HASBEENADDEDTOYOURLIBRARY', { listName });
			const trackingValue = isFollowed ? 0 : 1;

			await this.$apollo.mutate<SetInFollowedListMutation, SetInFollowedListMutationVariables>({
				mutation: SetInFollowedListDocument,
				variables: {
					input: { id: listId, state: !isFollowed, name: listName },
				},
			});

			toastSuccess({ message: message as string });
			this.followedListsCountRefetch();

			TrackingHelper.trackEvent('userinteraction', {
				action: 'follow_content',
				label: 'list',
				property: listId,
				value: trackingValue,
			});
		} catch (e) {
			toastError({ message: this.$tc('WEBAPP_ALERT_ERROR_TITLE'), duration: 2000 });
		}
	}
}
