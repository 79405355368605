
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';

import {
	SnowplowContext,
	SnowplowImpressionContext2,
	SnowplowModuleContext,
} from '@/helpers/tracking/providers/snowplow-contexts';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';

import type { ObjectType } from '@/@types/graphql-types';
import { SnowplowTitleContextGraphql, TrackingProviderPropertiesInterface } from '@/helpers/tracking/providers';

const sendImpressionContextWrapper = (vm: ImpressionTrackingMixin) => (e: Event) => {
	vm.sendImpressionContext();
};

export type ObservableOptions = {
	active: boolean;
	intersection: IntersectionOptions;
	once: boolean;
	throttle: number;
	callback: Function;
};

export type IntersectionOptions = {
	root: any;
	rootMargin: string;
	threshold: number;
};

/**
 * this Mixin works with the IntersectionObserver of Poster
 * If {defaultIntersectionOptions.threshold}% of the target element is visible on the viewport then we want to add it
 * to the queue that will be added to the impressionContext
 *
 * hooks methods:
 * onVisible
 *
 */
@Component
export default class ImpressionTrackingMixin extends Vue {
	@Prop({ default: () => [] }) additionalContexts: (SnowplowModuleContext | SnowplowContext)[];
	@Prop({ type: String, default: '' }) impressionTrackingCategory: string;
	@Prop({ type: String, default: '' }) impressionTrackingLabel: string;
	@Prop({ type: String, default: '' }) impressionTrackingProperty: string;
	@Prop({ default: undefined }) impressionTrackingValue: number | undefined;
	@Prop({ type: Boolean, default: false }) impressionTrackingActive: boolean;

	@Prop({ default: () => ({}) }) impressionTrackingObservableOptions: Partial<ObservableOptions>;

	@Prop({ default: null }) declare titleObjectId: number;
	@Prop({ default: null }) declare titleObjectType: ObjectType;

	static defaultObservableOptions: ObservableOptions = {
		active: false,
		intersection: {
			root: null,
			rootMargin: '0px 0px 0px 0px',
			threshold: 0.6, // 60% visibility of each element
		},
		once: true,
		throttle: 300,
		callback: () => ({}),
	};

	// uses Set for simplicity and to avaid having duplicates
	private list: Set<string> = new Set();
	private sent: Set<any> = new Set();
	private timer: number;

	get intersectionOptions(): IntersectionOptions {
		return {
			...ImpressionTrackingMixin.defaultObservableOptions.intersection,
			...this.impressionTrackingObservableOptions.intersection,
		};
	}

	private created() {
		if (!process.server && this.impressionTrackingActive) {
			/**
			 * Specific to the detail page
			 * Listen to onPosterClick and trigger trackEvent before the page change title.
			 */
			this.$on('onPosterClick', () => {
				this.sendImpressionContext();
			});

			// send the impressionContext before tab/browser will be closed
			if ('onbeforeunload' in window) {
				window.addEventListener('beforeunload', sendImpressionContextWrapper(this));
			}

			this.timer = setInterval(this.sendImpressionContext, 1000) as any;
		}
	}

	public beforeDestroy() {
		this.$off('onPosterClick');
		window.removeEventListener('beforeunload', sendImpressionContextWrapper(this));
		if (!process.server && this.impressionTrackingActive) {
			this.sendImpressionContext();
			clearInterval(this.timer);
		}
	}

	/**
	 * return observable options object for active tracking element
	 * Can be overwritten for Component's specific customization
	 *
	 * @param {PopularTitle | NewTitle} titleObject
	 */
	getObservableOptions(titleId: string): ObservableOptions {
		const { impressionTrackingObservableOptions, onVisible } = this;

		return {
			...ImpressionTrackingMixin.defaultObservableOptions,
			...impressionTrackingObservableOptions,
			intersection: this.intersectionOptions,
			callback: (isVisible?: boolean, entry?: any) => onVisible(titleId, isVisible, entry),
		};
	}

	/**
	 * IntersectionObserver hook
	 */
	@Emit('onVisibleCallback')
	onVisible(id: string, isVisible?: boolean, entry?: IntersectionObserverEntry) {
		this.visibilityCallback(!!isVisible);
		if (isVisible) {
			if (!this.sent.has(id)) {
				// if element is new add it to the list
				this.list.add(id);
			}
		}
	}

	visibilityCallback(isVisible: boolean) {}

	@Emit('impressionCallback')
	impressionCallback() {}

	sendImpressionContext() {
		const { list, impressionTrackingCategory, impressionTrackingActive, getImpressionContext, additionalContexts } =
			this;

		let contexts =
			this.titleObjectId && this.titleObjectType
				? (additionalContexts || []).concat([
						new SnowplowTitleContextGraphql(this.titleObjectId, this.titleObjectType),
				  ])
				: additionalContexts || [];

		// only track new values
		const newImpressions = [...list.values()];
		if (newImpressions.length) {
			const properties: TrackingProviderPropertiesInterface = { action: 'impression', nonInteraction: true };
			if (this.impressionTrackingLabel) properties.label = this.impressionTrackingLabel;
			if (this.impressionTrackingProperty) properties.property = this.impressionTrackingProperty;
			if (this.impressionTrackingValue) properties.value = this.impressionTrackingValue;

			if (impressionTrackingActive && impressionTrackingCategory !== '') {
				TrackingHelper.trackEvent(impressionTrackingCategory, properties, [
					getImpressionContext(newImpressions),
					...contexts,
				]);

				this.impressionCallback();
			}

			list.clear();
		}
	}

	private getImpressionContext(tokens: string[]) {
		return new SnowplowImpressionContext2(tokens, tokens.length);
	}
}
