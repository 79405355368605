
import { Component, Mixins, Prop } from 'vue-property-decorator';

import { ObjectType } from '@/@types/graphql-types';
import { ListMutationTitleDetailParam } from '@/helpers/providers/title-actions-provider';

import { ModalHelper } from '@/helpers/modal-helper';

import BaseModal from '@/components/modals/BaseModal.vue';
import ChipButton from '@/ui-components/ChipButton.vue';

import { icon, library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';

import TitleLikelistMutationMixin from '@/mixins/title-quick-action-mutations/TitleLikelistMutationMixin.vue';
import TitleDislikelistMutationMixin from '@/mixins/title-quick-action-mutations/TitleDislikelistMutationMixin.vue';

library.add(faCheckCircle);

@Component({
	name: 'LikeDislikePollModal',
	components: {
		BaseModal,
		ChipButton,
	},
})
export default class LikeDislikePollModal extends Mixins(TitleLikelistMutationMixin, TitleDislikelistMutationMixin) {
	@Prop({ required: true }) modalTitle: string;
	@Prop({ required: true }) titleId: string;
	@Prop({ required: true }) titleDetails: ListMutationTitleDetailParam;

	checkCircleIcon = icon({ prefix: 'far', iconName: 'check-circle' });

	private timeoutID?: ReturnType<typeof setTimeout>;

	get questionText() {
		const titleType = this.titleDetails.objectType === ObjectType.Movie ? 'WEBAPP_MOVIE' : 'WEBAPP_SHOW';
		return this.$t('WEBAPP_DID_YOU_LIKE_THE', { titleType: this.$t(titleType) });
	}

	onLike() {
		this.mixin_setInLikelist(this.titleId, false, false, this.titleDetails, {
			source: 'like_dislike_poll_modal',
		});

		this.close();
	}

	onDislike() {
		this.mixin_setInDislikelist(this.titleId, false, false, this.titleDetails, {
			source: 'like_dislike_poll_modal',
		});

		this.close();
	}

	onMouseOver() {
		if (this.timeoutID) {
			clearTimeout(this.timeoutID);
		}
	}

	onMouseLeave() {
		this.setTimeout();
	}

	close() {
		ModalHelper.close('like_dislike_poll_modal');
	}

	setTimeout() {
		this.timeoutID = setTimeout(() => this.close(), 4500);
	}

	mounted() {
		this.setTimeout();
	}
}
