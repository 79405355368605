
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SnowplowContext, SnowplowTitleContextGraphql } from '@/helpers/tracking/providers';
import {
	SetInLikelistDocument,
	SetInLikelistMutation,
	SetInLikelistMutationVariables,
} from '@/graphql/mutation/SetInLikelist.mutation';
import { TitleListName } from '@/interfaces/responses/title-list';
import { TrackingHelper, TrackingListEventPayload } from '@/helpers/tracking/tracking-helper';
import { addTitleToListToast, removeTitleFromListToast } from '@/helpers/toast/toast-helper';
import { preventNonSignedInAction } from '@/helpers/prevent-non-signed-in-action-helper';
import { ListMutationTitleDetailParam, TitleQuickActionPayload } from '@/helpers/providers/title-actions-provider';

@Component
export default class TitleLikelistMutationMixin extends Vue {
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];

	async mixin_setInLikelist(
		titleId: string,
		isTitleInLikeList: boolean,
		isTitleInDislikeList: boolean,
		titleDetails: ListMutationTitleDetailParam,
		trackingPayload?: {
			source?: string;
			property?: string;
		},
		onComplete?: () => void
	) {
		const preventAction = await preventNonSignedInAction();

		if (preventAction) {
			return null;
		}

		return this.$apollo
			.mutate<SetInLikelistMutation, SetInLikelistMutationVariables>({
				mutation: SetInLikelistDocument,
				variables: {
					input: {
						id: titleId,
						state: !isTitleInLikeList,
					},
				},
				update: () => {
					const source = trackingPayload?.source ?? '';

					const contexts = [...this.additionalContexts];
					const hasTitleContext = contexts.find(context => context.__name.includes('title'));

					if (!hasTitleContext) {
						contexts.push(
							new SnowplowTitleContextGraphql(
								titleDetails.objectId,
								titleDetails.objectType,
								titleDetails.seasonNumber,
								titleDetails.episodeNumber,
								titleDetails.contentType
							)
						);
					}
					if (onComplete) {
						onComplete();
					}

					if (trackingPayload) {
						const payload: TrackingListEventPayload = { action: source };

						if (trackingPayload?.property) {
							payload.property = trackingPayload.property;
						}

						isTitleInDislikeList && this.trackDislikelistRemoveEvent(payload, contexts);
						isTitleInLikeList
							? this.trackLikelistRemoveEvent(payload, contexts)
							: this.trackLikelistAddEvent(payload, contexts);
					}

					if (!isTitleInLikeList) {
						addTitleToListToast(TitleListName.LIKELIST, titleDetails, () =>
							this.mixin_setInLikelist(titleId, true, false, titleDetails, trackingPayload)
						);
					} else {
						removeTitleFromListToast(TitleListName.LIKELIST, titleDetails);
					}
				},
			})
			.then(() => {
				const payload: TitleQuickActionPayload = {
					titleObjectId: titleDetails.objectId,
					type: TitleListName.LIKELIST,
					isTitleInList: isTitleInLikeList,
					objectType: titleDetails.objectType,
				};
				this.$emit('title-quick-action', payload);
			});
	}

	trackLikelistAddEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.LIKELIST, 'add', payload, contexts);
	}

	trackLikelistRemoveEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.LIKELIST, 'remove', payload, contexts);
	}

	trackDislikelistRemoveEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.DISLIKELIST, 'remove', payload, contexts);
	}
}
