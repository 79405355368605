
import { Component, Mixins, Prop } from 'vue-property-decorator';
import FollowListMutationMixin from '@/mixins/lists/FollowListMutationMixin.vue';

@Component({
	name: 'ListToggle',
	components: {},
})
export default class ListToggle extends Mixins(FollowListMutationMixin) {
	@Prop() isFollowed: boolean;
	@Prop() listId: string;
	@Prop() listName: string;

	async onFollowToggle() {
		await this.mixin_onFollowToggle({
			listId: this.listId,
			listName: this.listName,
			isFollowed: this.isFollowed,
		});
		this.$emit('click');
	}
}
