import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type SetInLikelistMutationVariables = Types.Exact<{
	input: Types.SetInTitleListInput;
}>;

export type SetInLikelistMutation = {
	__typename?: 'Mutation';
	setInLikelist: {
		__typename?: 'SetInTitleListSeasonPayload';
		title:
			| {
					__typename?: 'Movie';
					id: string;
					likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			  }
			| {
					__typename?: 'Season';
					id: string;
					likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			  }
			| {
					__typename?: 'Show';
					id: string;
					likelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
					dislikelistEntry?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			  };
	};
};

export const SetInLikelistDocument = gql`
	mutation SetInLikelist($input: SetInTitleListInput!) {
		setInLikelist(input: $input) {
			title {
				id
				likelistEntry {
					createdAt
				}
				dislikelistEntry {
					createdAt
				}
			}
		}
	}
`;
