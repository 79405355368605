
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BasicButton from '@/ui-components/BasicButton.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BaseModal from '@/components/modals/BaseModal.vue';
import { ModalHelper } from '@/helpers/modal-helper';
import { AccountModal } from '@/lazy-load-components';
import { AccountModes } from '@/enums/login';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { namespace } from 'vuex-class';

const Modal = namespace('Modal');

@Component({
	name: 'ListsForSignedInUsersModal',
	components: { BasicButton, FontAwesomeIcon, BaseModal },
})
export default class ListsForSignedInUsersModal extends Vue {
	@Prop({ type: Boolean, default: false }) closable: boolean;
	@Prop() header: string;
	@Prop() body: string;

	@Modal.Getter private isOpen!: boolean;
	@Modal.Action private setOpen!: (value: boolean) => void;

	AccountModes = AccountModes;

	openAccountModal(startScreen: AccountModes.SIGN_IN | AccountModes.SIGN_UP) {
		ModalHelper.close().then(() => {
			this.setOpen(false);
		});
		ModalHelper.openModal(
			AccountModal,
			{
				closable: true,
				startScreen,
			},
			{ cssClass: 'account-modal' }
		).then(() => {
			this.setOpen(true);
		});
		TrackingHelper.trackEvent('user_account', {
			action: startScreen === AccountModes.SIGN_IN ? 'account_sign_in_clicked' : 'account_create_clicked',
			property: 'WatchlistSignupModal',
		});
	}
}
