import { Backdrop, ObjectType } from '@/@types/graphql-types';
import type { ModalOptions } from '@ionic/core/dist/types/components/modal/modal-interface';

export const MarkAsSeenModalOptions: Omit<ModalOptions, 'component'> = {
	backdropDismiss: true,
	cssClass: 'mark-as-seen-modal',
	swipeToClose: true,
	id: 'mark-as-seen-modal',
};

export interface MarkAsSeenModalProps {
	titleId: string;
	titleObjectType: ObjectType;
	titleObjectId: number;
	titleName?: string;
	seenEpisodeCount?: number;
	posterUrl?: string;
	backdrops: Pick<Backdrop, 'backdropUrl'>[];
}

export enum MarkAsSeenEvent {
	VIEW = 'view',
	DONE = 'done',
}

export enum MarkAsSeenView {
	PROMPT = 'prompt',
	SEEN_EPISODES = 'seen-episodes',
	SUCCESS = 'success',
}

export enum MarkAsSeenPromptViewEvent {
	ALL = 'all',
	NONE = 'none',
	SOME = 'some',
}

export enum MarkAsSeenEpisodesViewEvent {
	CANCEL = 'cancel',
	DONE = 'done',
	DONE_SUCCESS = 'done_success',
	FETCHING_CHANGE = 'fetching_change',
}
