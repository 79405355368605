import * as Types from '../../@types/graphql-types';

import gql from 'graphql-tag';
export type SetInWatchlistMutationVariables = Types.Exact<{
	input: Types.SetInTitleListInput;
	country: Types.Scalars['Country']['input'];
}>;

export type SetInWatchlistMutation = {
	__typename?: 'Mutation';
	setInWatchlistV2: {
		__typename?: 'SetInTitleListPayload';
		title:
			| {
					__typename?: 'Movie';
					id: string;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			  }
			| {
					__typename?: 'Show';
					id: string;
					seenState: { __typename?: 'ShowSeenState'; seenEpisodeCount: number };
					seasons: Array<{
						__typename?: 'Season';
						id: string;
						show: {
							__typename?: 'Show';
							id: string;
							watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
						};
					}>;
					watchlistEntryV2?: { __typename?: 'TitleListEntry'; createdAt: any } | null;
			  };
	};
};

export const SetInWatchlistDocument = gql`
	mutation SetInWatchlist($input: SetInTitleListInput!, $country: Country!) {
		setInWatchlistV2(input: $input) {
			title {
				id
				watchlistEntryV2 {
					createdAt
				}
				... on Show {
					seenState(country: $country) {
						seenEpisodeCount
					}
					seasons {
						id
						show {
							id
							watchlistEntryV2 {
								createdAt
							}
						}
					}
				}
			}
		}
	}
`;
