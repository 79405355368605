import * as Types from '../../../../@types/graphql-types';

import gql from 'graphql-tag';
export type SetInFollowedListMutationVariables = Types.Exact<{
	input: Types.SetInFollowedListInput;
}>;

export type SetInFollowedListMutation = {
	__typename?: 'Mutation';
	setInFollowedList: {
		__typename?: 'SetInFollowedListPayload';
		titleList: {
			__typename?: 'GenericTitleList';
			id: string;
			followedlistEntry?: { __typename?: 'FollowedListEntry'; createdAt: any; name: string } | null;
		};
	};
};

export const SetInFollowedListDocument = gql`
	mutation SetInFollowedList($input: SetInFollowedListInput!) {
		setInFollowedList(input: $input) {
			titleList {
				id
				followedlistEntry {
					createdAt
					name
				}
			}
		}
	}
`;
